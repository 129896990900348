/* eslint-disable no-irregular-whitespace*/
import { Button, Card, Col, Icon, Layout, List, Row } from "antd";
import { StyleSheet, css } from "aphrodite";
import { getVirtualKioskActivities, retrieveMerits } from "../Checkin-Web/constant/api";
import { useEffect, useState } from "react";
import { Colors } from "../../theme/Colors";
import { Fonts } from "../../theme/Fonts";
import { Footer } from "./Layout/Footer";
import { Header } from "./Layout/Header";
import LoadingScreen from "../UI-Components/LoadingScreen";
import LocationIcon from "../../assets/Images/Icons/location-icon.svg";
import React from "react";
import { Some } from "../../utils/Some";
import { Timer } from "./Layout/Timer";
import defaultOrg from "../../assets/Images/Icons/defaultOrg.svg";
import { getPolicies } from "../../constant/api";
import { handleLocation } from "../../utils/GeoLocationHelper";
import history from "../../history";
import { useDefaultErrorHandler } from "../../utils/useDefaultErrorHandler";
import { useFeatureFlag } from "../../hooks/launchDarkly";
import { usePolicy } from "../../hooks/usePolicyRules";

const styles = StyleSheet.create({
  activityTitle: {
    color: Colors.blackPearl,
    display: "inline-block",
    fontFamily: Fonts.roboto,
    fontSize: "18px",
    overflow: "hidden",
    textAlign: "left",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "263px",
  },
  buttonWrapper: {
    alignItems: "center",
    background: Colors.checkinButtonBg,
    bottom: "22px",
    display: "flex",
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    height: "50px",
    justifyContent: "space-between",
    position: "absolute",
    textTransform: "uppercase",
    width: "267px",
  },
  cardInnerWrapper: {
    height: "auto",
    marginLeft: "2%",
    marginRight: "2%",
    width: "96%",
  },
  cardWrapper: {
    borderRadius: "4px",
    height: "210px",
    marginBottom: "8px",
    width: "327px",
  },
  checkinText: {
    color: Colors.sherpa,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    letterSpacing: "0.15px",
    marginTop: "5px",
    textAlign: "center",
  },
  contentWrapper: {
    alignItems: "center",
    height: "80vh",
    justifyContent: "center",
    marginTop: "5px",
    minWidth: "375px",
    overFlow: "scroll",
    overflowX: "hidden",
  },
  eyeIcon: {
    color: Colors.lineBackground,
    width: "9px",
  },
  flexWrapper: {
    textAlign: "center",
  },
  footerWrapper: {
    alignItems: "center",
    bottom: 0,
    display: "flex",
    height: "10vh",
    justifyContent: "center",
    position: "sticky",
  },
  locationIcon: {
    marginRight: 10,
  },
  locationText: {
    color: Colors.blackPearl,
    display: "inline-block",
    fontFamily: Fonts.roboto,
    fontSize: "15px",
    marginTop: 2,
  },
  meritCountText: {
    color: Colors.darkRed,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
  },
  rightArrow: {
    fontSize: "16px",
    marginTop: "3px",
  },
  selectText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "24px",
    letterSpacing: "0.25px",
    textAlign: "center",
  },
});

export const SelectActivity = () => {
  const { Content } = Layout;
  const [kioskEvents, setKioskEvents] = useState<EventObject[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isLocationLoader, setIsLocationLoader] = useState<boolean>(false);
  const { push } = history;
  const homePage = localStorage.getItem("homePage");
  const name = localStorage.getItem("name");
  const logoUrl = localStorage.getItem("logoUrl");
  const { errorHandler } = useDefaultErrorHandler();
  const { getRules } = usePolicy();
  const { showM6CreateEditPolicyScreens } = useFeatureFlag();

  useEffect(() => {
    clearLocationDetails();
    getKioskEvents();
  }, []);

  const clearLocationDetails = () => {
    localStorage.removeItem("locationData");
    localStorage.removeItem("deviceLocation");
    localStorage.removeItem("manualLocation");
  };

  const getKioskEvents = async () => {
    const response = await getVirtualKioskActivities();
    const kioskEventsResponse = response.data.item;
    setLoading(false);
    setKioskEvents(kioskEventsResponse);
  };

  const handleNavigation = async (eventData: EventObject) => {
    await localStorage.removeItem("eventData");
    history.push({ pathname: "/web-checkin/checkInWith", state: { eventData } });
  };

  const handleViewMerits = async (eventData: EventObject) => {
    try {
      setLoading(true);
      const response = await getPolicies(eventData.event.sigmaOrgId);
      if (response.item.length) {
        const policyIds = eventData.event.policyIds.split(",");
        const requiredPolicy = response.item
          .filter((_: PolicyRunResponse) => policyIds.includes(_.id))
          .map((policy: PolicyRunResponse) => ({ authorized: false, policy }));
        const templateIDs = requiredPolicy.reduce(
          (templateIDs: string[], { policy }: { authorized: boolean; policy: PolicyRunResponse }) => {
            const requiredTemplateIds = getRules(policy).reduce((ids: string[], own) => {
              return own.arguments.length > 1
                ? [
                    ...ids,
                    ...own.arguments.slice(
                      1,
                      showM6CreateEditPolicyScreens ? own.arguments.length - 1 : own.arguments.length,
                    ),
                  ]
                : ids;
            }, []);

            return [...templateIDs, ...requiredTemplateIds];
          },
          [],
        );

        const urlQuery = `?isKiosk=1`;

        const {
          data: { item },
        } = await retrieveMerits(urlQuery, { templateIDs });

        push({
          pathname: "/web-checkin/view-merits",
          state: { eventData, policyRunDetails: requiredPolicy, validateMeritsResponse: item },
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  const handleMeritText = (eventData: EventObject) => {
    const policiesLength = eventData.event.policyIds.length > 0 ? eventData.event.policyIds.split(",").length : 0;
    if (policiesLength === 1) {
      return `${policiesLength} policy required`;
    }

    return `${policiesLength} policies required`;
  };

  const getLocation = (eventData: EventObject) => {
    setLoading(true);
    setIsLocationLoader(true);
    handleLocation({ actionType: "checkin", eventData, hasBackButton: true });
  };

  return (
    <div className="selectActivitiesWrapper">
      <React.Fragment>
        {loading ? (
          <LoadingScreen
            key={1}
            minHeight="calc(100vh - 125px)"
            deviceLocationMessage={isLocationLoader ? "Please enable location" : undefined}
          />
        ) : (
          <React.Fragment>
            {homePage === "selectactivity" ? (
              <Header
                orgLogo={logoUrl ? logoUrl : defaultOrg}
                exitButton={false}
                backArrow={false}
                orgName={name ? name : ""}
              />
            ) : (
              <div>
                <Timer />
                <Header
                  backArrow
                  exitButton
                  handleExit={() => push("/web-checkin/home")}
                  handleGoBack={() => push("/web-checkin/home")}
                />
              </div>
            )}
            <Content>
              <div className={css(styles.contentWrapper)}>
                <Row type="flex" justify="space-around" align="middle">
                  <Row className={css(styles.flexWrapper)} justify="space-around">
                    <h1 className={css(styles.checkinText)} data-testid="checkInHeader-SelectActivity">
                      Check-in
                    </h1>
                    <h2 className={css(styles.selectText)}>Select an activity</h2>
                    <List
                      grid={{
                        gutter: 4,
                        sm: 2,
                        xs: 1,
                      }}
                      dataSource={kioskEvents}
                      renderItem={(eventData: EventObject) => (
                        <List.Item>
                          <Card className={css(styles.cardWrapper)}>
                            <div className={css(styles.cardInnerWrapper)}>
                              <h3
                                className={css(styles.activityTitle)}
                                data-testid={`${eventData.event.name}-Activity-SelectActivity`}
                              >
                                {eventData.event.name}
                              </h3>
                              {eventData.event.locationEnabled && (
                                <Row type="flex">
                                  <Col>
                                    <img src={LocationIcon} className={css(styles.locationIcon)} width="12" />
                                  </Col>
                                  <Col>
                                    <span
                                      className={css(styles.locationText)}
                                      data-testid={`${eventData.event.name}-LocationRequired-SelectActivity`}
                                    >
                                      Your location is required to check-in
                                    </span>
                                  </Col>
                                </Row>
                              )}
                              {Some(eventData.event.policyIds) &&
                                eventData.event.policyIds &&
                                eventData.event.policyIds.length && (
                                  <Row style={{ margin: "10px 0px" }} type="flex" justify="space-between">
                                    <Col>
                                      <span
                                        className={css(styles.meritCountText)}
                                        data-testid={`${eventData.event.name}-MeritCountText-SelectActivity`}
                                      >
                                        {handleMeritText(eventData)}
                                      </span>
                                    </Col>
                                    <Col>
                                      <Icon
                                        type="eye"
                                        theme="filled"
                                        className={css(styles.eyeIcon)}
                                        onClick={() => {
                                          handleViewMerits(eventData);
                                        }}
                                        style={{ fontSize: "16px", verticalAlign: "middle" }}
                                        data-testid={`${eventData.event.name}-Icon-SelectActivity`}
                                      />
                                    </Col>
                                  </Row>
                                )}
                              <Button
                                type="primary"
                                className={css(styles.buttonWrapper)}
                                onClick={
                                  eventData.event.locationEnabled
                                    ? () => getLocation(eventData)
                                    : () => handleNavigation(eventData)
                                }
                                data-testid={`${eventData.event.name}-CheckinButton-SelectActivity`}
                              >
                                check-in
                                <Icon type="right" className={css(styles.rightArrow)} />
                              </Button>
                            </div>
                          </Card>
                        </List.Item>
                      )}
                    />
                  </Row>
                </Row>
              </div>
            </Content>
            <div className={css(styles.footerWrapper)}>
              <Footer />
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    </div>
  );
};
