/* eslint-disable no-irregular-whitespace*/
import { Button, Col, Collapse, Icon, Layout, Row, Typography } from "antd";
import React, { useEffect, useMemo, useState } from "react";
// eslint-disable-next-line no-unused-vars
import { RouteComponentProps, withRouter } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { Colors } from "../../theme/Colors";
import { Fonts } from "../../theme/Fonts";
import { Some } from "../../utils/Some";
import history from "../../history";
import { usePolicy } from "../../hooks/usePolicyRules";

const { Panel } = Collapse;
const { Text } = Typography;

interface Props
  extends RouteComponentProps<
    {},
    {},
    {
      policyRunDetails: PolicyRunDetails[];
      highlightFailedPolicy?: boolean;
      validateMeritsResponse: readonly ValidateMerit[];
      eventData: EventObject;
    }
  > {}

const ViewMerits = ({ location }: Props) => {
  const { Content } = Layout;
  const { goBack } = history;
  const [activeKeys, setActiveKeys] = useState<string[]>([]);
  const { getRules } = usePolicy();
  const policyRunDetails: PolicyRunDetails[] =
    location.state !== undefined && Some(location.state.policyRunDetails) ? location.state.policyRunDetails : [];
  const validateMeritsResponse =
    location.state !== undefined && Some(location.state.validateMeritsResponse)
      ? location.state.validateMeritsResponse
      : [];
  const highlightFailedPolicy: boolean = location.state?.highlightFailedPolicy ?? false;

  const activityName = useMemo(() => {
    if (location.state !== undefined && Some(location.state.eventData)) {
      try {
        return location.state.eventData.event.name;
      } catch (error) {
        return undefined;
      }
    }

    return undefined;
  }, [location.state]);

  const styles = StyleSheet.create({
    activityText: {
      color: Colors.blackPearl,
      fontFamily: Fonts.roboto,
      fontSize: 12,
      marginTop: 10,
      paddingLeft: 5,
    },
    activityTitle: {
      color: Colors.blackPearl,
      fontFamily: Fonts.roboto,
      fontSize: 16,
      fontWeight: 500,
      marginTop: 10,
    },
    bulletIconInvalidMerit: {
      alignItems: "center",
      backgroundColor: "red",
      border: "1px solid black",
      borderRadius: 20,
      display: "flex",
      fontSize: 14,
      height: 28,
      justifyContent: "center",
      width: 28,
    },
    bulletIconValidMerit: {
      alignItems: "center",
      border: "1px solid black",
      borderRadius: 20,
      display: "flex",
      fontSize: 14,
      height: 28,
      justifyContent: "center",
      width: 28,
      ...(highlightFailedPolicy && { backgroundColor: "#0fe20f" }),
    },
    cardInnerWrapper: {
      height: "auto",
      marginLeft: "4%",
      width: "94%",
    },
    cardTitle: {
      color: Colors.headerText,
      fontFamily: Fonts.roboto,
      fontSize: "16px",
      fontWeight: 500,
      letterSpacing: "1.25px",
      marginTop: "10px",
    },
    cardWrapper: {
      height: "auto",
      width: 450,
      ...(highlightFailedPolicy && { backgroundColor: "#aee4ae", border: "1px solid black" }),
    },
    closeIcon: {
      color: Colors.sherpa,
      fontSize: "16px",
      marginTop: "23px",
      textAlign: "right",
    },
    contentWrapper: {
      fontFamily: Fonts.roboto,
    },
    failedPolicyCardWrapper: {
      backgroundColor: "#FFCCCB",
      border: "1px solid black",
      height: "auto",
      width: "450px",
    },
    headerDesk: {
      color: Colors.blackPearl,
      fontFamily: Fonts.roboto,
      fontSize: 14,
      paddingInline: 20,
      textAlign: "center",
      width: 450,
    },
    headerFlex: {
      display: "flex",
    },
    titleWrapper: {
      alignItems: "center",
      color: Colors.sherpa,
      fontSize: "16px",
      lineHeight: "24px",
      marginTop: "20px",
      textAlign: "center",
      textDecoration: "underline",
      width: "95%",
    },
  });

  const setActivePanel = (keys: string[]) => {
    setActiveKeys(keys);
  };

  const meritStatus = (status?: string) => {
    switch (status) {
      case "accepted":
        return "Found, Valid";
      case "pending":
        return "Found, Pending";
      case "rejected":
        return "Found, Rejected";
      case "live":
        return "Live";
      case "paused":
        return "Paused";
      default:
        return "Not Found";
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="qualification-wrapper">
      <React.Fragment>
        <Content className={css(styles.contentWrapper)}>
          <Row type="flex" justify="space-around" align="middle" style={{ flexDirection: "column" }}>
            <Row>
              <Row className={css(styles.headerFlex)}>
                <h4 className={css(styles.titleWrapper)} data-testid="requiredPoliciesText-ViewMerits">
                  Policies
                </h4>
                <Icon
                  type="close"
                  className={css(styles.closeIcon)}
                  data-testid="closeIcon-ViewMerits"
                  onClick={() => goBack()}
                />
              </Row>
              <Row justify="center">
                <p className={css(styles.headerDesk)} data-testid="header-ViewMerits">
                  <b>{activityName ?? ""}</b> requires that the following validation checks are performed in order to
                  successfully check-in.
                </p>
              </Row>
              {policyRunDetails.map(({ policy, authorized }, index: number) => (
                <div style={{ marginTop: "30px" }}>
                  <p className={css(styles.cardTitle)}>Policy #{index + 1}</p>
                  <div data-testid={`${policy.name}-Card-ViewMerits`}>
                    <Collapse
                      bordered={false}
                      className={css(
                        authorized === false && highlightFailedPolicy
                          ? styles.failedPolicyCardWrapper
                          : styles.cardWrapper,
                      )}
                      onChange={setActivePanel}
                      activeKey={activeKeys}
                    >
                      <Panel
                        header={
                          <div className={css(styles.cardInnerWrapper)}>
                            <Row justify="space-between" style={{ alignItems: "center", paddingTop: 18 }}>
                              <Col span={22}>
                                <Row
                                  style={{ display: "flex", justifyContent: "space-arround", justifyItems: "center" }}
                                >
                                  <Text
                                    className={css(styles.activityTitle)}
                                    data-testid={`${policy.name}-PolicyName-ViewMerits`}
                                  >
                                    {`${policy.name},`}
                                  </Text>
                                  <Text
                                    className={css(styles.activityText)}
                                    data-testid={`${policy.name}-OrgName-ViewMerits`}
                                  >
                                    {policy.owner.name}
                                  </Text>
                                </Row>
                              </Col>
                              <Col span={2} style={{ alignItems: "center" }}>
                                <Button
                                  type="primary"
                                  style={{
                                    backgroundColor: "inherit",
                                    border: "none",
                                    boxShadow: "none",
                                    marginTop: 5,
                                  }}
                                >
                                  <Icon
                                    data-testid={`${policy.name}-ArrowIcon-ViewMerits`}
                                    type={activeKeys.includes(policy.id) ? "down" : "right"}
                                    style={{ color: "black", float: "right" }}
                                  />
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        }
                        key={policy.id}
                        style={{ borderBottom: "none" }}
                        showArrow={false}
                      >
                        <Row>
                          <Col span={24} style={{ alignItems: "center" }}>
                            <Row>
                              <Col span={14} offset={2}>
                                <Text style={{ color: Colors.blackPearl, fontWeight: 500 }}>
                                  Merit, Issuing Organization
                                </Text>
                              </Col>
                              <Col span={8}>
                                <Text style={{ color: Colors.blackPearl, fontWeight: 500, paddingLeft: 12 }}>
                                  Status
                                </Text>
                              </Col>
                            </Row>
                            {getRules(policy).map((own, ownIndex) => {
                              if (own.arguments.length > 1) {
                                const merits = own.arguments.reduce((merits: ValidateMerit[], templateId) => {
                                  const merit = validateMeritsResponse.find(({ id }) => id === templateId);

                                  return Some(merit)
                                    ? [...merits, { name: merit.name, orgName: merit.orgName, state: merit.state }]
                                    : merits;
                                }, []);

                                const invalidMerit = merits.find(
                                  ({ state }) => Some(state) && ["rejected", "pending", "not found"].includes(state),
                                );

                                return (
                                  <>
                                    <Row>
                                      <Col span={2}>
                                        <div
                                          className={
                                            !authorized && Some(invalidMerit)
                                              ? css(styles.bulletIconInvalidMerit)
                                              : css(styles.bulletIconValidMerit)
                                          }
                                        >
                                          {ownIndex + 1}
                                        </div>
                                      </Col>
                                      <Col span={22} style={{ paddingTop: 5 }}>
                                        {merits.map(({ name, orgName, state }, index) => (
                                          <>
                                            {index !== 0 && (
                                              <Row>
                                                <Col>
                                                  <Text
                                                    style={{ color: Colors.blackPearl, fontWeight: 500 }}
                                                    data-testid={`${policy.name}-OrText-ViewMerits`}
                                                  >
                                                    OR
                                                  </Text>
                                                </Col>
                                              </Row>
                                            )}
                                            <Row justify="space-between">
                                              <Col span={16}>
                                                <Text data-testid={`${policy.name}-${name}-TemplateName-ViewMerits`}>
                                                  {`${name}, ${orgName}`}
                                                </Text>
                                              </Col>
                                              <Col span={8}>
                                                <div style={{ alignItems: "center", display: "flex" }}>
                                                  {state === "accepted" ? (
                                                    <>
                                                      <CheckIcon style={{ color: "green", fontSize: 15 }} />
                                                      <Text
                                                        data-testid={`${policy.name}-${name}-TemplateState-ViewMerits`}
                                                        style={{ paddingLeft: 2, textAlign: "right" }}
                                                      >
                                                        {meritStatus(state)}
                                                      </Text>
                                                    </>
                                                  ) : (
                                                    <>
                                                      {Some(state) &&
                                                        ["pending", "rejected", "not found"].includes(state) && (
                                                          <ClearIcon style={{ color: "red", fontSize: 15 }} />
                                                        )}
                                                      <Text
                                                        data-testid={`${policy.name}-${name}-TemplateState-ViewMerits`}
                                                        style={{ paddingLeft: 2, textAlign: "right" }}
                                                      >
                                                        {meritStatus(state)}
                                                      </Text>
                                                    </>
                                                  )}
                                                </div>
                                              </Col>
                                            </Row>
                                          </>
                                        ))}
                                      </Col>
                                    </Row>
                                    {ownIndex < policy.rules.own.length - 1 && (
                                      <Row
                                        style={{
                                          borderTop: `2px dotted black`,
                                          height: 1,
                                          marginInline: "8%",
                                        }}
                                      ></Row>
                                    )}
                                  </>
                                );
                              }

                              return null;
                            })}
                          </Col>
                        </Row>
                      </Panel>
                    </Collapse>
                  </div>
                </div>
              ))}
            </Row>
          </Row>
        </Content>
      </React.Fragment>
    </div>
  );
};

export default withRouter(ViewMerits);
