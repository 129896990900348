import { useCallback } from "react";
import { useFeatureFlag } from "./launchDarkly";

export const usePolicy = () => {
  const { showM6CreateEditPolicyScreens } = useFeatureFlag();

  const getRules = useCallback(
    (policy: PolicyResponse) => {
      if (showM6CreateEditPolicyScreens) {
        const rules = [];
        if (policy.formula) {
          const { own } = policy.formula;
          if (own.formulaType === "Conjunction" && own.conjunction) {
            rules.push(...own.conjunction);
          }
          if (own.formulaType === "Disjunction" && own.disjunction) {
            rules.push(...own.disjunction);
          }
        }

        return rules;
      }

      return policy.rules.own;
    },
    [showM6CreateEditPolicyScreens],
  );

  return { getRules };
};
